// import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import React, { Component } from "react";
// import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { dealWithError, PersonDocument } from "../libs/ei-helpers";
import "./Login.css";
import { Form, Icon, Input, Result, Checkbox, Card, Radio, List } from 'antd';
import MaskedInput from 'antd-mask-input'

const { Meta } = Card;

class SignupFormComponent extends React.Component {
  params = {}
  state = {
    isLoading: false,
    person_document: "",
    person_type: "LEGAL",
    contact_name: "",
    mobile: "",
    email: "",
    password: "",
    contract_due_day: "1",
    formVisible: true,
    accepted_terms: false,
    doc: null
  }

  constructor(props) {
    super(props);

    this.params = this.props.match.params;

    this.props.appSetTitle("Signup");
  }

  async componentDidMount() {
    this.setState({ isLoading: false });
  }

  checkDocument = async (rule, value, callback) => {
    const person_type = this.state.person_type
    const person_document = value

    this.state.doc = new PersonDocument(person_document, person_type)

    if (this.state.doc.isValid())
      callback();

    callback("O documento informado não é válido...")
  }

  validateForm = () => {
    // console.log('validateForm', this.state.contact_name.length > 0, this.state.mobile.length > 0, this.state.email.length > 0, this.state.contract_due_day > 0, this.state.accepted_terms, this.state.doc != null && this.state.doc.isValid())

    return this.state.contact_name.length > 0
      && this.state.mobile.length > 0
      && this.state.email.length > 0
      && this.state.contract_due_day > 0
      && this.state.accepted_terms
      && this.state.doc != null && this.state.doc.isValid()

  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const registerInfo = await API.post("ei-api", `/signup/subscribe/`, {
        body: {
          person_document: this.state.doc.digits,
          person_type: this.state.person_type,
          contact_name: this.state.contact_name,
          mobile: this.state.mobile,
          email: this.state.email,
          contract_due_day: this.state.contract_due_day,
          accepted_terms: this.state.accepted_terms
        }
      })

      if (registerInfo.status !== 'success')
        throw new Error(registerInfo.error)

      this.setState({ formVisible: false });

      // await Auth.signIn(this.state.email, this.state.password);
      // this.props.userHasAuthenticated(true);
    } catch (e) {
      dealWithError('Cadastro', e)
    }

    this.setState({ isLoading: false });
  }

  onBlur = event => {
    const field_name = event.target.id || event.target.name
    const field_value = event.target.value

    if (field_name === "email")
      this.setState({
        [field_name]: field_value.toLowerCase()
      })
  }

  onChange = event => {
    const field_name = event.target.id || event.target.name
    const field_value = event.target.value

    // console.debug('onChange', field_name, field_value)

    this.setState({
      [field_name]: field_value
    })
  }

  toggleTerms = () => {
    this.setState({
      accepted_terms: !this.state.accepted_terms
    })
  }

  render() {
    const userHasAuthenticated = this.state.userHasAuthenticated
    const { getFieldDecorator } = this.props.form;

    const data = [
      'PTA integrado digitalmente ao CREA-SP',
      'Validações automáticas compatíveis com cada meta',
      'Agenda de atividades e lembretes',
      'Assinatura digital dos documentos'
    ];

    return (
      !userHasAuthenticated &&
      <div className="Signup">
        <Form onSubmit={this.handleSubmit} className="signup-form">
          <img src="/imgs/ei_logo.png" className="logo" alt="Entidade Integrada" />
          {this.state.formVisible &&
            <div>
              <Form.Item label="CNPJ da Entidade">
                {getFieldDecorator("person_document", {
                  initialValue: this.state.person_document,
                  rules: [
                    {
                      required: true,
                      message: "O CNPJ da entidade é obrigatório..."
                    },
                    {
                      documentType: "person_type",
                      validator: this.checkDocument
                    }
                  ]
                })(
                  <MaskedInput
                    id="person_document"
                    type="text"
                    prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    mask="11.111.111/1111-11"
                    placeholder={
                      this.state.person_type !== "NATURAL"
                        ? "00.000.000/0000-00"
                        : "000.000.000-00"
                    }
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                  />
                )}
              </Form.Item>
              <Form.Item label="Nome do presidente">
                <Input
                  id="contact_name"
                  prefix={<Icon type="contacts" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Nome do Presidente"
                  onChange={this.onChange}
                />
              </Form.Item>
              <Form.Item label="E-mail do presidente">
                <Input
                  id="email"
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="E-mail"
                  value={this.state.email}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                />
              </Form.Item>
              <Form.Item label="Celular do Presidente">
                <MaskedInput
                  id="mobile"
                  prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  mask="11 11111-1111"
                  placeholder="99 99999-9999"
                  onChange={this.onChange}
                />
              </Form.Item>
              {/* <Form.Item>
                <Input
                  id="password"
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Senha"
                  // value={this.state.password}
                  onChange={this.onChange}
                />
              </Form.Item> */}
              <Form.Item>
                <Card bordered={true}>
                  <Meta
                    avatar={<Icon type="check-square" theme="twoTone" style={{ fontSize: '50px' }} />}
                    title="Plano 1 - PTA Online"
                    description="R$ 500,00 / mês"
                  />
                  <List
                    header={<div>Recursos</div>}
                    size="small"
                    dataSource={data}
                    renderItem={item => (
                      <List.Item>
                        {item}
                      </List.Item>
                    )}
                  />
                  <div className="ant-input" style={{ width: '100%', height: '280px', marginTop: '20px', overflow: 'scroll', fontSize: '10px', lineHeight: '1.6em' }}>
                    <b>TERMOS E CONDIÇÕES DE USO DO SISTEMA DE PRESTAÇÃO DE CONTAS ENTIDADE INTEGRADA</b><br />
                    <br />
                    Os serviços do Portal ENTIDADE INTEGRADA (“Entidade Integrada”) são fornecidos por METAURO AUTOMACAO COMERCIAL LTDA, inscrita no CNPJ/MF sob o nº 10.754.453/0001-67, com sede na PC LUCINDA BRASI BRANDAO nº 60, LOTEAMENTO INOCOOP, Mogi Mirim, SP, Brasil, CEP 13806-516 (“Representante/Metauro”). <br />
                    <br />
                    Ao usar os serviços ENTIDADE INTEGRADA, você concorda com as presentes condições gerais de uso do SISTEMA DE PRESTAÇÃO DE CONTAS (“Condições Gerais”). Por isso, é impositiva a leitura dessas Condições Gerais, com atenção. <br />
                    <br />
                    Produtos e Serviços disponibilizados dentro do SISTEMA DE PRESTAÇÃO DE CONTAS ENTIDADE serão regidos por essas Condições Gerais, mas poderão se aplicar termos ou exigências adicionais a determinados Produtos e/ou Serviços. Os termos adicionais, se aplicáveis, estarão disponíveis com os Produtos e/ou Serviços, e se incorporarão a essas Condições Gerais, caso você utilize esses Produtos e/ou Serviços. <br />
                    <br />
                    Objeto: <br />
                    <br />
                    Estas Condições Gerais têm por objeto regular o seu acesso ao SISTEMA DE PRESTAÇÃO DE CONTAS ENTIDADE (“Portal”), disponibilizado pela METAURO, através da Internet, primordialmente pelo endereço www.entidadeintegrada.com.br, a este não se limitando, bem como estabelecer condições relativas à licença de uso de programas de computador disponibilizados no portal (“Produtos”), de titularidade da METAURO ou de terceiros, sem prejuízo de eventuais termos adicionais, aplicáveis a alguns Produtos e/ou Serviços, que se incorporarão a essas Condições Gerais. <br />
                    <br />
                    Condições de Acesso: <br />
                    <br />
                    O acesso ao Portal será público e gratuito, através do endereço www.entidadeintegrada.com.br, ou outro indicado pela Entidade Integrada, a seu critério. <br />
                    <br />
                    Ao conceder acesso ao Portal, ou a qualquer funcionalidade nele disponível de forma gratuita, a Entidade Integrada não transfere a você (“Usuário”) quaisquer direitos de propriedade industrial e/ou intelectual sobre qualquer componente, criação, programa de computador, imagem, desenho, ou qualquer outro item disponível no Portal, concedendo apenas e tão somente uma licença de uso, não exclusiva, intransferível, e revogável a qualquer tempo, a critério da Entidade Integrada. <br />
                    <br />
                    À Entidade Integrada fica reservado o direito de, a qualquer momento e a seu exclusivo critério, modificar as condições de acesso ao Portal, alterando seu design e/ou sua forma de apresentação, incluindo ou suprimindo funcionalidades e/ou informações, ou realizando qualquer outra alteração, de qualquer ordem, sem que tal fato resulte em qualquer direito do Usuário reclamar perdas e danos, a qualquer título. <br />
                    <br />
                    Sua Conta no Portal: <br />
                    <br />
                    Caso o Usuário crie uma conta para acesso ao Portal, deverá fornecer dados verdadeiros e precisos, ficando responsável pelo sigilo de seu nome de usuário e senha, que são pessoais e intransferíveis. <br />
                    <br />
                    Qualquer atividade a partir da conta do Usuário no Portal, mediante a utilização de suas credenciais de acesso (nome de usuário e senha), ainda que indevida, será de responsabilidade do Usuário. <br />
                    <br />
                    Serviços e Produtos fornecidos mediante pagamento: <br />
                    <br />
                    O acesso a Serviços e Produtos fornecidos mediante pagamento será liberado ao Usuário em até 24 (vinte e quatro) horas após a contratação e o pagamento pelos Serviços e Produtos. <br />
                    <br />
                    Licenças de Uso de Programas de Computador: <br />
                    <br />
                    Os programas de computador fornecidos no Portal (“Produtos”), gratuitamente ou mediante pagamento, são licenciados ao Usuário de forma não exclusiva, intransferível, e temporária, observadas as seguintes condições: <br />
                    <br />
                    A. Todos os Produtos disponibilizados serão acessíveis on-line, via Internet, através do Portal, sujeitos às disposições destas Condições Gerais. <br />
                    <br />
                    B. Licenças de uso de Produtos concedidas gratuitamente, ou por período de testes gratuitos, terão validade pelo período determinado pelo titular dos direitos autorais do programa de computador (“Licenciante”), que poderá ser reduzido ou estendido, a qualquer tempo e a critério do Licenciante, e independentemente de prévio aviso. <br />
                    <br />
                    C. Licenças de uso de Produtos concedidas gratuitamente, ou por período de testes gratuitos poderão ter limitações em relação à versão paga, a critério do Licenciante, e não contarão com qualquer tipo de suporte ou garantia, implícita ou explícita, a qualquer título, inclusive de nível de serviço. <br />
                    <br />
                    D. Licenças de uso de Produtos em fase de pré-lançamento não contarão com qualquer tipo de suporte ou garantia, implícita ou explícita, a qualquer título, inclusive de nível de serviço. <br />
                    <br />
                    E. Ao final do período de qualquer licença de uso de Produtos concedida por período de testes gratuitos, o Usuário deverá manifestar, expressamente, seu eventual interesse na contratação do Produto testado, sob pena de ter seu acesso ao Produto interrompido, automaticamente, ao final do período de testes, ficando cientes que todos os dados do Usuário, inseridos por ele no Produto, durante os testes, serão automaticamente apagados. <br />
                    <br />
                    F. Os Produtos licenciados deverão ser utilizados somente para as operações do Usuário, e por prepostos a serviço do Usuário. <br />
                    <br />
                    G. Somente usuários autorizados poderão fazer uso dos Produtos licenciados, e somente usuários administradores poderão adicionar ou excluir usuários autorizados, bem como alterar a contratação dos Produtos, com a inclusão/exclusão de novos usuários, módulos, ou qualquer outra característica disponível. <br />
                    <br />
                    H. Todas as licenças de uso comercializadas através do Portal são de caráter temporário, válidas pelo período pago pelo Usuário, e o não pagamento de qualquer novo período ensejará o cancelamento imediato do acesso ao programa de computador licenciado, que somente será reestabelecido mediante a quitação do débito. <br />
                    <br />
                    I. Talvez seja necessário que o Usuário instale um software cliente para acessar e usar o Produto licenciado, especialmente se tratando de dispositivos móveis. Nessa hipótese, os Usuários deverão observar os termos destas Condições Gerais também no uso do software cliente, sem prejuízo de observar condições específicas de licenciamento do referido software cliente. <br />
                    <br />
                    J. Ao adquirir qualquer licença de uso de Produtos, no Portal, e fornecer seus dados de cobrança e/ou cartão de crédito aos parceiros de intermediação financeira da Entidade Integrada, o Usuário autoriza a cobrança recorrente pela licença, sempre que vencido o período temporário de validade da licença. A cobrança seguirá os valores ajustados, e aceitos pelo Usuário no ato da contratação da licença de uso do Produto, comprometendo-se o Usuário, assim, ao pagamento do valor periódico da licença de uso do Produto. <br />
                    <br />
                    K. Os Produtos licenciados através do Portal serão constantemente atualizados, de forma automática, sempre que houver lançamento de nova versão, podendo, assim, ocorrer, a qualquer tempo, mudanças nos Produtos licenciados, em decorrência de atualizações de versão, com o que consente o Usuário, ao contratar a licença de qualquer Produto. <br />
                    <br />
                    L. Sem prejuízo de modificações em decorrência de atualizações, a Entidade Integrada e/ou o Licenciante poderão, a qualquer tempo, alterar a forma de fornecimento de qualquer Produto, a seu exclusivo critério, e independentemente de aviso prévio, com o que anui o Usuário. Adicionalmente, a Entidade Integrada e/ou o Licenciante poderão, a qualquer tempo, estabelecer limites no uso do Produto, a seu exclusivo critério, que serão comunicados previamente ao Usuário. <br />
                    <br />
                    M. Os Produtos disponíveis no Portal são licenciados ao Usuário da forma como estão, não existindo opção de qualquer customização para as necessidades do Usuário, que deverá se assegurar de que o Produto, da forma como está disponível, atende às suas necessidades, não podendo imputar qualquer responsabilidade à Entidade Integrada ou ao Licenciante, caso verifique, no futuro, que o Produto licenciado não atende à totalidade de suas necessidades. <br />
                    <br />
                    N. Qualquer dano, a qualquer título, decorrente de mau uso, acidente, imperícia, imprudência ou negligência na operação, pelo Usuário, dos programas de computador licenciados, é de exclusiva responsabilidade do Usuário, sem qualquer responsabilidade, solidária ou subsidiária, e a qualquer título, da Entidade Integrada ou do Licenciante. <br />
                    <br />
                    O. A Entidade Integrada e/ou o Licenciante não assumem qualquer responsabilidade por danos ao Usuário, decorrentes de tentativas de reparo ou alterações não autorizadas nos programas licenciados, invasões no sistema, vírus, e outros elementos nocivos. <br />
                    <br />
                    P. A Entidade Integrada e/ou o Licenciante não assumem qualquer responsabilidade pelas decisões do Usuário, baseadas no uso dos programas de computador licenciados. <br />
                    <br />
                    <br />
                    O Usuário declara estar ciente que pequenos defeitos de programação (bugs) são comuns a todos os programas de computador, isentando, assim, a Entidade Integrada e o Licenciante, de qualquer responsabilidade por danos decorrentes de tais bugs usuais, limitando a responsabilidade da Licenciante à correção desses pequenos defeitos de programação. <br />
                    <br />
                    Os direitos não expressamente garantidos ao Usuário, através destas Condições Gerais, são reservados à Entidade Integrada e/ou ao Licenciante do programa de computador adquirido pelo Usuário. <br />
                    <br />
                    Garantias de Níveis de Serviço: <br />
                    <br />
                    Para os Produtos e Serviços fornecidos mediante pagamento, a Entidade Integrada garante ao Usuário a disponibilidade de 99,9% (noventa e nove virgular nove por cento) do tempo ao mês, calculada de acordo com a seguinte fórmula: <br />
                    <br />
                    PAM = (TMM – TMP)/TMM <br />
                    <br />
                    PAM = Porcentagem do tempo de atividade do mês <br />
                    <br />
                    TMM = Total de minutos no mês <br />
                    <br />
                    TMP = Total de minutos de paralisação no mês <br />
                    <br />
                    Caso a disponibilidade, em qualquer mês, seja inferior à garantida acima, a Entidade Inetgrada concorda em conceder ao Usuário os seguintes créditos: <br />
                    <br />
                    Porcentagem de Atividade do Mês <br />
                    <br />
                    Nível de Crédito <br />
                    <br />
                    &lt; 99,9% <br />
                    <br />
                    10% sobre o valor do mês. <br />
                    <br />
                    &lt; 99% <br />
                    <br />
                    20% sobre o valor do mês. <br />
                    <br />
                    &lt; 95% <br />
                    <br />
                    40% sobre o valor do mês. <br />
                    <br />
                    Os níveis de crédito descritos na tabela acima não são cumulativos, e representam o recurso exclusivo do Usuário, em caso de descumprimento dos níveis de serviço, até pela natureza do serviço, que poderá ser afetado por diversos fatores alheios à vontade da Entidade Integrada, conforme prevê o artigo 8º, do Código de Defesa do Consumidor. Fica ajustado, ainda, que o crédito concedido jamais excederá, em nenhuma circunstância, o valor mensal pago pelo Usuário pelo Produto ou Serviço afetado pelo descumprimento do nível de serviço. <br />
                    <br />
                    Para fazer jus aos créditos previstos acima, o Usuário deverá enviar solicitação formal à Entidade Integrada, indicando a data e hora da(s) ocorrência(s) que importou(aram) em descumprimento do nível de serviço. Dentro de 10 (dez) dias após a solicitação do Usuário, a Entidade Integrada averiguará a eventual procedência da solicitação, e, se procedente, informará ao Usuário o nível de crédito, que será calculado sobre o valor do mês em que ocorreu o descumprimento do nível de serviço, e descontado da fatura com vencimento no mês subsequente. <br />
                    <br />
                    Não serão computados como minutos de paralisação no mês, para efeito de cálculo da porcentagem do tempo de atividade do mês, as paradas decorrentes de manutenção preventiva, ou de qualquer ocorrência alheia ao controle e diligência da Entidade Integrada, tais como falta de energia fornecida pela concessionária, falhas nas operadoras interconectadas à rede, eventos de força maior e caso fortuito, entre outros, e estes não se limitando. <br />
                    <br />
                    Serviços de suporte técnico serão fornecidos pela Entidade Integrada através dos meios divulgados em http://entidadeintegrada.desk360.com.br, de segunda a sexta-feira, das 8:00 às 18:00 horas, com tempo de resposta de até 24 (vinte e quatro) horas, não havendo quaisquer serviços de suporte técnico nas dependências do Usuário inclusos.<br />
                    <br />
                    Privacidade: <br />
                    <br />
                    A Entidade Integrada se compromete a manter em sigilo os dados que forem inseridos pelo Usuário, em áreas restritas do Portal, durante a utilização de Produtos licenciados, abstendo-se de divulgá-los a terceiros. <br />
                    <br />
                    Observado o sigilo descrito acima, o Usuário autoriza à Entidade Integrada coletar determinadas informações relacionadas ao Usuário e aos dados por ele inseridos durante a utilização de Produtos licenciados através do Portal, através de ferramentas e rotinas automatizadas, e sem intervenção humana, com objetivo de traçar perfil do Usuário e/ou integração com outros sistemas (“Perfil do Usuário”). <br />
                    <br />
                    O Usuário consente com o recebimento de ofertas da Entidade Integrada e de Parceiros, durante o uso do Portal, seus Produtos e Serviços, baseadas no Perfil do Usuário, previamente coletado pela Entidade Integrada, declarando que tal fato não resulta em qualquer violação de sua privacidade. <br />
                    <br />
                    Em virtude da natureza do Portal, dos Serviços, e dos Produtos, a Entidade Integrada terá de notificar o Usuário periodicamente, sobre anúncios importantes relativos à operação do Portal. Como uma condição do Portal, dos Serviços, e dos Produtos, o Usuário poderá receber, periodicamente, comunicações de marketing, e outros tipos de comunicações, relacionadas ao Portal, seus Serviços e Produtos. <br />
                    <br />
                    Limitação e Isenção de Responsabilidade: <br />
                    <br />
                    É de integral responsabilidade do Usuário a manutenção de contrato com qualquer provedor de serviços de Internet, para o fornecimento dos recursos mínimos necessários ao acesso ao Portal, e aos Produtos e Serviços contratados, sendo igualmente de responsabilidade do Usuário a aquisição e manutenção de equipamentos que atendam aos requisitos mínimos necessários ao acesso ao Portal, e aos Produtos e Serviços Contratados. <br />
                    <br />
                    A Entidade Integrada não se responsabiliza por perdas e danos causados pela utilização irregular do Portal, seus Produtos e Serviços, ou pela utilização inadequada dos equipamentos do Usuário, nem por falhas na instalação elétrica do Usuário, que deverá ser adequada aos equipamentos utilizados para acesso ao Portal, excluindo de sua responsabilidade, ainda, a ocorrência de efeitos de caso fortuito e força maior. <br />
                    <br />
                    É de responsabilidade exclusiva do Usuário verificar a adequação do(s) programa(s) de computador licenciado(s) ao seu ramo de atividade, notadamente às regras tributárias e fiscais, municipais, estaduais e federais aplicáveis ao Usuário, uma vez que o(s) programa(s) de computador licenciado(s) é(são) fornecido(s) sem qualquer customização ou parametrização para a localidade e o ramo de atividade específico do Usuário, não havendo garantias a qualquer título, implícitas ou explícitas, da Entidade Integrada e/ou do Licenciante, quanto à adequação do(s) programa(s) de computador à legislação vigente e aplicável à atividade do Usuário. <br />
                    <br />
                    Também não são de responsabilidade da Entidade Integrada quaisquer perdas e danos suportados pelo Usuário em função de problemas software, hardware, sistemas, e aplicativos do Usuário, fornecidos por terceiros, bem como perdas de dados, invasões, vírus, e qualquer outro evento que fuja ao controle e diligência da Entidade Integrada. <br />
                    <br />
                    O backup realizado pela Entidade Integrada possui a finalidade exclusiva de garantir a restauração dos serviços em caso de pane nos sistemas de armazenamento, não permitindo restauração de dados danificados por falhas no uso do Produto licenciado. Entretanto, uma cópia de segurança poderá ser baixada pelo usuário nos formatos disponibilizados pelo Produto licenciado. <br />
                    <br />
                    Considerando que os termos destas Condições Gerais, e os exibidos no Portal ou em condições específicas para aquisição de Produtos e/ou Serviços disponibilizados no Portal alocam os riscos entre a Entidade Integrada e o Usuário, e que os preços dos Produtos e Serviços disponibilizados refletem esta alocação de riscos, acordam as partes que, em qualquer hipótese, qualquer indenização decorrente dos Produtos e Serviços disponibilizados pela Entidade Integrada e seu representante, por danos diretos ou indiretos, se e quando existir alguma responsabilidade, ficará limitada ao valor total pago pelo Usuário à Entidade Integrada e seus representantes, desde o início da contratação de qualquer Produto ou Serviço no Portal, até a data do prejuízo, ainda que eventual prejuízo exceda tal valor. <br />
                    <br />
                    O Usuário reconhece que a Entidade Integrada não controla nem aprova conteúdo que o usuário adiciona aos Produtos por ele utilizados, que será de responsabilidade integral do Usuário, sem qualquer responsabilidade da Entidade Integrada e seus representantes. <br />
                    <br />
                    Propriedade Intelectual e Industrial: <br />
                    <br />
                    Pertencem exclusivamente aos titulares dos Produtos os direitos de propriedade industrial e intelectual sobre os Produtos licenciados através do Portal, cujo uso será permitido ao Usuário, de acordo com os termos destas Condições Gerais. <br />
                    <br />
                    O Usuário não poderá ter acesso aos códigos fonte dos Produtos, nem a camadas fechadas dos Produtos, ficando ciente que a tentativa de acesso a esses itens, ou o efetivo acesso a esses itens, por qualquer meio, constituirá violação de direitos autorais do titular dos direitos sobre o Produto, sujeitando o Usuário às sanções previstas em Lei. <br />
                    <br />
                    O Usuário não poderá, direta ou indiretamente, inclusive através de atos de seus prepostos, copiar, modificar, transferir, sublicenciar, ceder, vender, dar em locação, dar em garantia, doar, ou alienar a terceiros os Produtos a que tiver acesso através do Portal, sob qualquer forma ou modalidade, gratuita ou onerosa, provisória ou permanente, no todo ou em parte, nem submetê-los a processos de engenharia reversa ou, ainda, traduzi-lo ou decodificá-lo, no todo ou em parte. As mesmas vedações aplicam-se a quaisquer documentações ou informações relativas aos Produtos. <br />
                    <br />
                    A Entidade Integrada pode citar o nome e marca do Usuário, como fonte de referência e/ou usuário do Portal, seus Serviços e Produtos, inclusive em sua publicidade, independentemente de qualquer pagamento adicional, com o que consente o Usuário. <br />
                    <br />
                    O Usuário se compromete a não utilizar as especificações dos Produtos, ou permitir que terceiros as utilizem, com vistas a criar outros de mesma destinação. <br />
                    <br />
                    A Entidade Integrada não garante que os Produtos operarão em combinações com outras, que não aquelas para as quais foram especificados os Produtos, e que sua operação será ininterrupta ou livre de erros. <br />
                    <br />
                    A responsabilidade da Entidade Integrada e/ou dos Representantes, em relação aos Produtos, ficará limitada à correção dos eventuais erros encontrados nos Produtos, assegurando ao Usuário a devolução dos valores pagos, caso a Entidade Integrada e/ou o Representantes não possam fazer com que o Produto opere conforme garantido. <br />
                    <br />
                    Os nomes, marcas e logotipos da Entidade Integrada, do Licenciante, ou de qualquer outro terceiros, contidas nos Produtos, e qualquer documentação que os acompanhe, não poderão ser adulterados ou modificados, bem como não poderão ser objeto de venda, licenciamento, doação, locação ou qualquer forma de transferência ou transmissão, onerosa ou gratuita, permanente ou temporária. <br />
                    <br />
                    Prazo e Rescisão: <br />
                    <br />
                    A relação entre a Entidade Integrada/Representantes e o Usuário, nos termos destas Condições Gerais, e de eventuais outras condições adicionais que a ela possam se acrescer, será por prazo indeterminado, podendo ser interrompida a qualquer tempo. <br />
                    <br />
                    A simples desistência de uso pelo Usuário não gera o cancelamento ou a rescisão do seu contrato com a Entidade Integrada/Representante. <br />
                    <br />
                    Não havendo interesse do usuário em continuar a utilizar quaisquer Serviços e Produtos disponibilizados no Portal, deverá o Usuário formalizar, expressamente, sua intenção de cancelamento do Serviço ou Produto, mediante acesso à área restrita do Portal, com a utilização de seu nome de usuário e senha, onde deverá realizar a solicitação de cancelamento do Serviço e/ou Produto, através de opção própria para cancelamento. <br />
                    <br />
                    O cancelamento se tornará efetivo na data em que se encerrar o ciclo temporário de validade da licença de uso em vigor, já pago anteriormente pelo Usuário, que valerá como aviso prévio do Usuário. <br />
                    <br />
                    Dentro desse período, o Usuário poderá utilizar livremente os Serviços e/ou Produtos contratados, que permanecerão disponíveis. <br />
                    <br />
                    Caso a Entidade Integrada, e/ou o Representante de qualquer Produto ou Serviço disponível através do Portal, resolva por descontinuar qualquer Produto ou Serviço, tal decisão será informada ao Usuário com antecedência de 90 (noventa) dias, que valerá como aviso prévio, durante o qual o Usuário poderá utilizar livremente o Produto ou Serviço em processo de descontinuidade, mediante o pagamento dos valores correspondentes. <br />
                    <br />
                    Em qualquer hipótese de rescisão, solicitada por qualquer das partes, não incidirão quaisquer cláusulas penais, ou dever de indenizar, ficando os ônus da parte que solicitou a rescisão limitados ao aviso prévio previsto nos parágrafos antecedentes. <br />
                    <br />
                    No caso de qualquer descumprimento destas Condições Gerais, ou de quaisquer outras condições associadas a qualquer Produto ou Serviço disponibilizado pelo Portal, o acesso do Usuário também poderá ser interrompido, com a rescisão dessas Condições Gerais e de qualquer outro contrato com o Usuário, mediante aviso prévio de 48 (quarenta e oito) horas. <br />
                    <br />
                    Durante o período de aviso prévio, o Usuário deverá efetuar o download de todos os seus dados, que eventualmente estejam armazenados nos Produtos ou Serviços em período de aviso prévio, visando criar uma cópia de segurança desses dados, pois a Entidade Integrada e poderá apagar todo e qualquer dado do Usuário que esteja armazenado em seus servidores, imediatamente após o encerramento do aviso prévio, e suspensão do acesso ao Usuário, sendo tais dados irrecuperáveis, após a remoção realizada, o que declara estar ciente o usuário. <br />
                    <br />
                    O download destes dados poderá ser realizado dentro da área restrita do Portal, mediante acesso da área de acesso para backup, e estará disponível nos formatos definidos pelo Programa, que podem ser consultados na mesma área. <br />
                    <br />
                    No caso de ser negado o pagamento recorrente de qualquer novo período de licença dos Produtos fornecidos mediante pagamento no Portal, o acesso a esses produtos será imediatamente suspenso, sendo que, não havendo regularização dos pagamentos pelo Usuário, após o prazo de 15 (dias) dias, o acesso ao Produto não pago será automaticamente cancelado, com a rescisão da contratação, e consequente deleção de todos os dados eventualmente inseridos pelo Usuário no Produto, sem que o Usuário tenha direito a qualquer indenização, a qualquer título. <br />
                    <br />
                    O Usuário reconhece e declara que, salvo o expressamente descrito nessas Condições Gerais, a Entidade Integrada e seus Representantes não terão obrigação de continuar a manter, exportar ou devolver os dados do Usuário, após expirado o aviso prévio de qualquer rescisão contratual, por qualquer motivo, reconhecendo o Usuário que a Entidade Integrada não terá nenhuma obrigação, de qualquer natureza, pela exclusão de dados do Usuário, de acordo com esses termos. <br />
                    <br />
                    Correção Monetária e Enquadramento Fiscal: <br />
                    <br />
                    Os preços pagos pelo Usuário por eventuais Serviços fornecidos mediante pagamento, serão corrigidos monetariamente, a cada 12 (doze) meses, ou na menor periodicidade que venha a ser permitida pela legislação em vigor, com base na variação positiva do IGPM/FGV, ou outro índice que venha a substituí-lo, no caso de sua extinção.   <br />
                    Os preços pagos pelo Usuário por eventuais Serviços fornecidos mediante pagamento, serão reavaliados e enquadrados conforme tabela de preços vigente a cada 12 (doze) meses, com base no enquadramento fiscal da empresa do Usuário." <br />
                    <br />
                    Disposições Finais: <br />
                    <br />
                    É vedado ao Usuário ceder ou transferir a terceiros os direitos e obrigações decorrentes do presente instrumento, total ou parcialmente, salvo se com a concordância prévia e expressa da Entidade Integrada. <br />
                    <br />
                    Todas as comunicações entre as partes poderão ser realizadas por carta, fax, ou e-mail, o que as partes declaram aceitar, neste ato, ficando cientes que a validade jurídica de todas as comunicações fica condicionada à existência de protocolo, ou aviso de recebimento, sob pena de serem tidas como não entregues. <br />
                    <br />
                    No caso de um tribunal ou juízo competente julgar ilegal, nula ou ineficaz qualquer estipulação destas Condições Gerais, as estipulações restantes permanecerão em plena força e vigor. Caso a estipulação ou estipulações julgadas ilegais, nulas ou ineficazes sejam de ordem a afetar substantivamente o equilíbrio das partes perante o presente acordo, elas deverão negociar, de boa fé, uma alternativa que, não contendo os vícios da estipulação ou estipulações invalidadas, reflitam, na maior extensão possível, as suas intenções originárias. <br />
                    <br />
                    A tolerância de uma parte para com a outra, relativamente a descumprimento de qualquer das obrigações assumidas, não será considerada novação ou renúncia a qualquer direito, constituindo mera liberalidade, que não impedirá a parte tolerante de exigir da outra o fiel cumprimento destas Condições Gerais, a qualquer tempo. <br />
                    <br />
                    No caso de uso de Produtos fora do território brasileiro, o Usuário concorda em cumprir, de forma integral, todas as Leis e Regulamentos de exportação do Brasil, e dos países de destino dos Produtos em eventual utilização, que é restrita a empresas do mesmo grupo econômico do Usuário, alocadas fora do país, e mediante prévia e expressa autorização da Entidade Integrada, para assegurar que nem os Programas, nem qualquer outro produto deles diretamente decorrente (1) sejam exportados, direta ou indiretamente, em violação às Leis de Exportação; ou (2) sejam obtidos com a intenção de serem usados para quaisquer propósitos proibidos pelas Leis de Exportação. <br />
                    <br />
                    Eventuais atualizações destas Condições Gerais serão publicadas no Portal, e submetidas à aceitação do Usuário, que, se não concordar com as alterações, terá a faculdade de dar por rescindido o contrato, sem quaisquer ônus, cessando seu acesso ao Portal, bem como aos Serviços e Produtos disponibilizados pela Entidade Integrada. <br />
                    <br />
                    As partes exercem a sua liberdade de contratar, observados os preceitos de ordem pública e o princípio da função social do presente contrato, que atende também aos princípios da economicidade, razoabilidade e oportunidade, permitindo o alcance dos respectivos objetivos societários das partes e atividades empresariais, servindo, consequentemente, a toda a sociedade. <br />
                    <br />
                    As partes declaram que sempre guardarão, na execução destas Condições Gerais, os princípios da probidade e da boa-fé, presentes também, tanto na sua negociação, quanto na sua celebração. <br />
                    <br />
                    Legislação Aplicável, Foro, e Encerramento: <br />
                    <br />
                    Estas Condições Gerais serão regidas e interpretadas de acordo com as Leis vigentes na República Federativa do Brasil. <br />
                    <br />
                    Elegem as partes o Foro da Comarca de São Paulo, como competente para dirimir qualquer dúvida ou controvérsia decorrente das presentes Condições Gerais, renunciando a qualquer outro, por mais privilegiado que seja, ou venha a ser. <br />
                  </div>
                </Card>
              </Form.Item>
              <Form.Item label="Melhor dia para vencimento do boleto" style={{ width: "100%" }}>
                <Radio.Group
                  id="contract_due_day"
                  name="contract_due_day"
                  onChange={this.onChange}
                  defaultValue={this.state.contract_due_day}
                  size="large"
                  buttonStyle="solid"
                  style={{ width: "100%" }}
                >
                  {/* <Radio.Button value="1">01</Radio.Button> */}
                  <Radio.Button value="5">05</Radio.Button>
                  <Radio.Button value="10">10</Radio.Button>
                  <Radio.Button value="15">15</Radio.Button>
                  <Radio.Button value="20">20</Radio.Button>
                  <Radio.Button value="25">25</Radio.Button>
                  <Radio.Button value="25">30</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('accept', {
                  valuePropName: 'checked',
                  initialValue: false,
                })(<Checkbox
                  id="accepted_terms"
                  onChange={this.toggleTerms}
                >
                  Li e aceito os termos de uso
                </Checkbox>)}
                <LoaderButton
                  block
                  disabled={!this.validateForm()}
                  htmlType="submit"
                  isLoading={this.state.isLoading}
                  text="Cadastrar minha entidade"
                  loadingText="Cadastrando…"
                />
                {/* Or <a href="">register now!</a> */}
              </Form.Item>
            </div>
          }
          {!this.state.formVisible &&
            <Result
              status="success"
              title="Solicitação enviada com sucesso!"
              subTitle="Em breve nossa equipe comercial entrará em contato com você com mais detalhes :-)"
            // extra={[
            //   <Button type="primary" key="console">
            //     Go Console
            //   </Button>,
            //   <Button key="buy">Buy Again</Button>,
            // ]}
            />
          }
        </Form>
      </div>
    )
  }
}

export default class Signup extends Component {
  MainForm = Form.create({ name: "form" })(SignupFormComponent);

  render() {
    const childProps = {
      // isAuthenticated: this.state.isAuthenticated,
      // isAppLoading: this.state.isAppLoading,
      match: this.props.match,
      history: this.props.history,
      appSetTitle: this.props.appSetTitle
    };

    return <this.MainForm {...childProps} />;
  }
}
