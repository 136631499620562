const dev = {
  STRIPE_KEY: "pk_test_GItCd4YTrgF3w1i0oCupfkl000O1F5HP2C",
  s3: {
    REGION: "us-east-1",
    BUCKET: "up2u-ei-dev-attachmentsbucket-cey83r7xba7"
  },
  apiGateway: {
    REGION: "us-east-1",
    // URL: "http://127.0.0.1:3030"
    // URL: "http://local.dev.entidadeintegrada.com.br:3030"
    URL: "https://api.dev.entidadeintegrada.com.br"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_uQo7Ob7P7",
    APP_CLIENT_ID: "59be8hn37hldfljh65ebtguhso",
    IDENTITY_POOL_ID: "us-east-1:93a65818-0e3d-48f7-bc4e-adf28d60b865"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_GItCd4YTrgF3w1i0oCupfkl000O1F5HP2C",
  s3: {
    REGION: "us-east-1",
    BUCKET: "up2u-ei-prod-attachmentsbucket-xixs4d803x2s"
  },
  apiGateway: {
    REGION: "us-east-1",
    // URL: "https://api.entidadeintegrada.com.br"
    URL: "http://local.dev.entidadeintegrada.com.br:3030"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_mIFYM96vS",
    APP_CLIENT_ID: "38iv46et8qecsnmsecrhfkh8oi",
    IDENTITY_POOL_ID: "us-east-1:becdf9f5-ef44-482b-88fd-33095a9876ae"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'dev' ? dev : prod;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 6000000,
  FORM_LAYOUT: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  },
  TAIL_FORM_ITEM_LAYOUT: {
    wrapperCol: {
      xs: {
        span: 16,
        offset: 0,
      },
      sm: {
        span: 8,
        offset: 8,
      },
    },
  },
  ...config
}